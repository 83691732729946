import NextOrderPage from "components/orderPage/NextOrderPage"
import { where } from "firebase/firestore"
import { getSpecialOffers } from "hooks/firestore/advanced/useSpecialOffers"
import { getCollection } from "hooks/firestore/core/useCollection"
import { getDocument } from "hooks/firestore/core/useDocument"
import { getQuery } from "hooks/firestore/core/useQuery"
import { clearCache, withExportCache, withImportCache } from "hooks/firestore/FirestoreCache"
import { getCategoriesRef, getShopRef, getShopsRef } from "hooks/firestore/getRefs"
import { GetStaticPaths, GetStaticProps } from "next"
import Shop from "types/firestore/shop"
import { ShortUrl } from "types/firestore/shorturl"
import sanitizeUrl from "util-functions/sanitizeUrl"

export const getStaticPaths: GetStaticPaths = async () => {
  const shorturls = (await getCollection<ShortUrl>("shorturls")) ?? []
  const shops = (await getQuery<Shop>(getShopsRef(), where("platformListed", "==", true))) ?? []

  // Get all platform listed shorturls
  const shortUrlPaths = shorturls
    .filter(shorturl => {
      if (!shorturl.id) {
        return false
      }
      const shop = shops.find(shop => shop._ref.id === shorturl.id)
      return !!shop?.platformListed
    })
    .map(shorturl => ({
      params: { shorthand: shorturl._ref.id },
    }))

  // Get all platform listed domains
  const domainPaths = shops
    .filter(shop => shop.domain)
    .map(shop => ({
      params: { shorthand: shop.domain },
    }))

  return { paths: [...shortUrlPaths, ...domainPaths], fallback: "blocking" }
}

export const getStaticProps: GetStaticProps = async ({ params }) => {
  const shorthand = (typeof params?.shorthand === "object" ? params?.shorthand?.[0] : params?.shorthand) ?? undefined
  if (!shorthand) {
    return { notFound: true }
  }

  // Check if shorthand is a domain
  const isCustomDomain = shorthand.includes(".")

  let shopId: string | null = null

  if (isCustomDomain) {
    // Handle custom domain
    const shop = await getQuery<Shop>(getShopsRef(), where("domain", "==", shorthand))
    if (shop && shop[0]) {
      shopId = shop[0]._ref.id
    } else {
      console.error(`ERROR: No shop found for custom domain ${shorthand}`)
      return { notFound: true }
    }
  } else {
    // Handle short URL
    const shorturl = await getDocument<ShortUrl>(`shorturls/${sanitizeUrl(shorthand)}`)
    if (!shorturl) {
      shopId = shorthand
      const shopDoc = await getDocument(getShopRef(shopId))
      if (shopDoc && shopDoc.shorthand) {
        return {
          redirect: {
            destination: `/${shopDoc.shorthand}`,
            permanent: true,
          },
        }
      } else {
        console.error("ERROR: No ShorthandDoc nor Shopdoc for this parameter")
        return { notFound: true }
      }
    } else {
      shopId = shorturl.id
    }
  }

  if (!shopId) {
    console.error(`ERROR: The shorthand doc shorturls/${shorthand} is missing a valid shop id.`)
    return { notFound: true }
  }

  clearCache()
  const shop = await getDocument(getShopRef(shopId))
  if (!shop) {
    console.error(`ERROR: Failed to load shop ${shopId} (shorthand ${shorthand}).`)
    return { notFound: true }
  }
  const offeringId = shop?.offering?.offeringId
  const storefrontId = shop?.offering?.storefrontId
  if (!offeringId) {
    console.error(`ERROR: Shop ${shopId} has no offeringId.`)
    return { notFound: true }
  }
  if (!storefrontId) {
    console.error(`ERROR: Shop ${shopId} has no storefrontId.`)
    return { notFound: true }
  }

  await getCollection(getCategoriesRef(offeringId, storefrontId))
  await getSpecialOffers(offeringId, storefrontId, true)

  const props = {
    props: withExportCache({ shopId }),
    revalidate: 30,
  }

  return props
}

interface OrderPageProps {
  shopId: string
}

function OrderPage({ shopId }: OrderPageProps) {
  return <NextOrderPage shopId={shopId} />
}

export default withImportCache(OrderPage)
