import { arrayUnion, Timestamp, updateDoc } from "firebase/firestore"
import { useBasket } from "hooks/firestore/simple/useBasket"
import { useBasketId } from "hooks/localstate/localstorage/useBasketId"
import { getDate } from "hooks/misc/useDate"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"

export function useEvaluateChannelAttribution() {
  const router = useRouter()
  const [pendingChannels, setPendingChannels] = useState<string[]>([])
  const [pendingPasswords, setPendingPasswords] = useState<string[]>([])
  const searchParams =
    typeof window !== "undefined" ? new URLSearchParams(window.location.search) : new URLSearchParams()
  const chl = searchParams.getAll("chl")
  searchParams.delete("chl")
  const pw = searchParams.getAll("pw")
  searchParams.delete("pw")
  const channels = typeof chl === "string" ? [chl] : chl || []
  const passwords = typeof pw === "string" ? [pw] : pw || []
  //Create a basket if necessary
  useBasketId(!!pendingChannels.length || !!pendingPasswords.length)
  const basket = useBasket()

  useEffect(() => {
    if (channels.length || passwords.length) {
      window.history.replaceState(null, document.title, "?" + searchParams.toString())

      if (channels.length) {
        setPendingChannels(prev => [...prev, ...channels])
      }
      if (passwords.length) {
        setPendingPasswords(prev => [...prev, ...passwords.map(password => password.toUpperCase())])
      }
    }
  }, [channels.length, passwords.length, router])

  useEffect(() => {
    if (basket && basket.status === "open" && (pendingChannels.length || pendingPasswords.length)) {
      updateDoc(basket._ref, {
        ...(pendingChannels.length
          ? {
              channelAttribution: arrayUnion(
                ...pendingChannels.map(channel => ({
                  channel,
                  timestamp: Timestamp.fromDate(getDate()),
                }))
              ),
            }
          : {}),
        ...(pendingPasswords.length ? { channelAttribution: arrayUnion(...pendingPasswords) } : {}),
      })
      setPendingChannels([])
      setPendingPasswords([])
    }
  }, [pendingChannels, pendingPasswords, basket])
}
